<template lang="pug">
  .permission-setting
    h6.tip {{ $t('account_management.permission_settings.tip') }}

    .container-fluid
      .row.roles-select
        div
          FormFieldLabel(
            :title="$t('account_management.user_role')"
            required
          )
        .col.col-sm-4
          AppDropdown.user-role(
            :creatable="hasEditPermission('permission_settings')"
            :editable="hasEditPermission('permission_settings')"
            :deletable="hasEditPermission('permission_settings')"
            close-on-select
            :loading="loading"
            :items="roles"
            :value="currentRole"
            :tips="$t('account_management.permission_settings.create_role_tip')"
            :search-placeholder="$t('account_management.permission_settings.search_or_create')"
            @select="selectRole"
            @create-item="newRole"
            @update-item="updateRoleName"
            @delete-item="removeRole"
          )
      .row.permissions-table-row(v-if="!isEmpty(currentRole)")
        PermissionsTable(
          :selected-permissions="currentPermissions"
          @change="changePermissions"
        )
      .row.button-container.justify-content-end
        AppSaveButton.m-t-30.m-b-30(
          name="confirm"
          title="actions.confirm"
          :disabled="loading || !hasEditPermission('permission_settings')"
          :short="false"
          @save="updateRolePermissions"
        )
</template>

<script>
  // store modules
  import permissionsModule from "@/config/store/permissions"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { isEmpty, head, find } from "lodash-es"
  import { PERMISSION_EDIT } from "@/config/constants"

  const permissionsMixin = withStoreModule(permissionsModule, {
    name: "permissions",
    readers: { permissions: "items", permissionsLoading: "loading" },
    actions: { fetchPermissions: "FETCH_ITEMS" }
  })

  export default {
    props: {
      roles: {
        type: Array,
        default: () => new Array()
      },
      rolesLoading: {
        type: Boolean,
        default: false
      },
      createRole: {
        type: Function,
        default: () => {}
      },
      updateRole: {
        type: Function,
        default: () => {}
      },
      deleteRole: {
        type: Function,
        default: () => {}
      }
    },
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      PermissionsTable: () => import("./PermissionsTable"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel")
    },

    mixins: [permissionsMixin, withPermissions],

    data() {
      return {
        currentRole: {},
        currentPermissions: [],
        PERMISSION_EDIT
      }
    },

    mounted() {
      this.selectRole(head(this.roles))
      // this.fetchPermissions()
    },

    watch: {
      roles() {
        if (isEmpty(this.currentRole)) {
          this.selectRole(head(this.roles))
        }
      }
    },

    computed: {
      loading() {
        return this.rolesLoading || this.permissionsLoading
      }
    },

    methods: {
      isEmpty,

      selectRole(role = {}) {
        this.currentRole = role
        this.currentPermissions = { ...(role.permissions || {}) }
      },

      newRole(name) {
        this.createRole({ name }).then(roles => {
          const createdRole = find(roles, { name })
          this.selectRole(createdRole)
        })
      },

      updateRoleName({ item, updated }) {
        this.updateRole({
          id: item.id,
          name: updated,
          permissions: item.permissions
        })
      },

      updateRolePermissions() {
        this.updateRole({
          id: this.currentRole.id,
          permissions: this.currentPermissions
        })
      },

      removeRole(id) {
        this.deleteRole(id)
        if (this.currentRole.id === id) {
          this.currentRole = {}
        }
      },

      changePermissions({ page, access_type }) {
        this.currentPermissions[page.name] = access_type
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .permission-setting
    .tip
      margin: 20px 0 10px
      font-size: 0.8rem
      font-weight: 300
      color: $default-gray

    .roles-select
      display: flex
      align-items: center
      margin-bottom: 15px

    .permissions-table-row, .button-container
      max-width: 900px
</style>
